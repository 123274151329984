import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const Footer = () => {
  return (
    <>
      <div className=" footer-container my-5">
        <div className="container">
          <div className="row align-items-center">
            <h1
              className="text-center m-auto text-danger"
              style={{ fontWeight: 300 }}
            >
              SPONSORS & PARTNERS
            </h1>
            <p className="text-center m-auto mt-2">WHO MAKES THIS POSSIBLE!</p>
            <div className="col-lg-12 col-md-4 my-5">
              <img className="img-fluid" src="\assets\sponsors.png" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="meeta-footer-section meeta-footer-3 ">
        <div className="footer-wrap">
          <div className="container">
            <div className="footer-widget-wrap">
              <Row>
                <Col xs={12} md={3}>
                  <h6 className="text-warning">FAIR SECRETARIAT</h6>
                  <p className="text-white">
                    Asian Tourism Fair House-97/1 ; 1st Floor (2B), Sukrabad,
                    Sher-e-bangla Nagar, Dhaka-1207, Bangladesh.
                  </p>
                </Col>

                <Col className="md-ps-5" xs={12} md={3}>
                  <h6 className="text-warning">FAIR ESSENTIALS</h6>
                  <ul>
                    <li>
                      <a href="https://www.asiantourismfair.com/fair-folder/">
                        Fair catalogue
                      </a>
                    </li>
                    <li>
                      <a href="https://www.asiantourismfair.com/exhibition-layout/">
                        Exibation Layout
                      </a>
                    </li>
                    <li>
                      <a href="https://www.asiantourismfair.com/exhibitors-center/">
                        Participation Rate
                      </a>
                    </li>
                    <li>
                      <a href="https://www.asiantourismfair.com/b2b/">
                        Booth Registration
                      </a>
                    </li>
                    <li>
                      <a href="https://www.asiantourismfair.com/logistics/">
                        Logistic Support
                      </a>
                    </li>
                    <li>
                      <a href="https://www.asiantourismfair.com/contact/">
                        Inquary
                      </a>
                    </li>
                  </ul>
                </Col>
                <Col className="md-ps-5" xs={12} md={3}>
                  <h6 className="text-warning">TRAVEL ESSENTIALS</h6>
                  <ul>
                    <li>
                      <a href="https://www.asiantourismfair.com/#">
                        About Bangladesh
                      </a>
                    </li>
                    <li>
                      <a href="https://mofa.gov.bd/site/view/service_box_items/FOREIGN%20MISSIONS%20IN%20BANGLADESH">
                        Visa Information
                      </a>
                    </li>
                    <li>
                      <a href="a#">BD Ambassy in Abroad</a>
                    </li>
                    <li>
                      <a href="a#">Official Hotels</a>
                    </li>
                    <li>
                      <a href="a#">Post Fair Tour</a>
                    </li>
                    <li>
                      <a href="a#">Local Transport</a>
                    </li>
                  </ul>
                </Col>
                <Col className="md-ps-5" xs={12} md={3}>
                  <h6 className="text-warning">NEWS</h6>
                  <ul>
                    <li>
                      <h2 className="text-white">Recent</h2>
                    </li>
                    <ul>
                      <li>
                        {" "}
                        <a href="https://www.asiantourismfair.com/10th-asian-tourism-fair-2023/">
                          {" "}
                          10th Asian Tourism Fair (ATF, Dhaka) will be held on
                          September 21, 22 & 23 – 2023.
                        </a>
                      </li>
                    </ul>
                  </ul>
                </Col>
              </Row>
            </div>
          </div>
          <div
            className="social-links"
            style={{ backgroundColor: "#FFCA04", color: "black" }}
          >
            <p className="text-center mx-auto">
              ©Copyright 2023 – Asian Tourism Fair(ATF). All Rights Reserved.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
