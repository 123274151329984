import { useEffect, useState } from "react";
import { VisitorAuthContext } from "../../Context/AuthContex/VisitorAuthContext";
import { BaseUrl } from "../../Helpers/Constant";
import axios from "axios";
import { Col, Container, Row } from "react-bootstrap";

import { Link } from "react-router-dom";
import Layout from "../../Components/Layout/Layout";
import HeaderBottom from "../../Components/HeaderBottom/HeaderBottom";
import seminarImg from "../../img/seminar-img.jpg";
import Loader from "../../Components/Loader/Loader";

interface ISeminar {
  fair_seminar_id: number;
  fair_seminar_name: string;
  fair_seminar_status: string;
}

export default function SeminarList() {
  const { visitorUser } = VisitorAuthContext();
  const [seminars, setSeminar] = useState<ISeminar[]>([]);
  const [isLoading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    try {
      (async () => {
        const { data } = await axios.get(
          `${BaseUrl}/api/fair/event/seminar/get/all/active`
        );

        if (data.success) {
          setSeminar(data.data);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })();
    } catch (err) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  }, [visitorUser?.visitor_id]);

  return (
    <>
      {isLoading && <Loader />}
      <Layout>
        <HeaderBottom pathName={"Seminar List"} />
        <Container className="text-center">
          <Row className="mx-auto">
            {seminars.length ? (
              <>
                {seminars.map((seminar: ISeminar) => (
                  <Col key={seminar?.fair_seminar_id} xs={12} md={6} lg={3}>
                    <div
                      className="events-card-bg event_container mb-4"
                      style={{ maxHeight: "20rem", minHeight: "20rem" }}
                    >
                      <div className="events-img-sec">
                        <Link
                          to={`/seminar-event-list/${seminar?.fair_seminar_id}`}
                        >
                          <div className="event_wrapper">
                            <div className="event_venue "></div>

                            <img
                              className="event-img"
                              style={{
                                minHeight: "14rem",
                                maxHeight: "14rem",
                                objectFit: "cover",
                              }}
                              alt=""
                              src={`${seminarImg}`}
                            />
                          </div>
                        </Link>
                      </div>
                      <div className="text-center">
                        <h4 className="">
                          <Link
                            to={`/seminar-event-list/${seminar?.fair_seminar_id}`}
                            className="text-decoration-none text-black"
                          >
                            {seminar?.fair_seminar_name}
                          </Link>
                        </h4>
                      </div>
                    </div>
                  </Col>
                ))}
              </>
            ) : (
              <p className="fs-2 text-danger">No data found</p>
            )}
          </Row>
        </Container>
      </Layout>
    </>
  );
}
