import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Layout from "../../Components/Layout/Layout";
import { ISingleEvent } from "../../Types/EventsType";
import { Container, Spinner } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { BsCalendarCheckFill } from "react-icons/bs";
import { FaUserTie } from "react-icons/fa";
import { BiTimeFive } from "react-icons/bi";
import { MdLocationPin } from "react-icons/md";
import moment from "moment";
import { BaseUrl, imgUrl } from "../../Helpers/Constant";
import SingleEventSpeaker from "../SingleEvent/SingleEventSpeaker";
import VisitorModal from "./VisitorModal";
import axios from "axios";
import { useAuthContext } from "../../Context/AuthContex/AuthContex";

const VisitorSingleEventList = () => {
  const { user } = useAuthContext();
  const { id } = useParams();
  const [singleEvent, setSingleEvent] = useState<ISingleEvent>();
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    setLoading(true);
    try {
      (async () => {
        const { data } = await axios.get(
          `${BaseUrl}/api/fair/event/get/single/${id}`
        );

        if (data.success) {
          setSingleEvent(data.data);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })();
    } catch (error) {
      setLoading(false);
    }
    setLoading(false);
  }, [id]);

  return (
    <>
      {
        <Layout>
          <div
            style={{
              paddingTop: "50px",
              paddingBottom: "50px",
              backgroundColor: "#f5f5f5",
            }}
          >
            <Container>
              {
                <div>
                  <div className='events-matrix-content event-details-main-cs'>
                    <div className='events-title '>
                      <h1>{singleEvent?.event_title}</h1>
                    </div>

                    <Row>
                      <Col xs={12} md={{ span: 7, order: "first" }}>
                        <div className='divider d-none d-md-block'></div>
                        <div className='events-matrix-info mt-3 '>
                          <div className='w-50 w-sm-100'>
                            <ul>
                              <li>
                                <div className='info-icon'>
                                  <BsCalendarCheckFill />
                                </div>
                                <div className='info-content'>
                                  <span>Date </span>
                                  <p>
                                    {moment(singleEvent?.event_date).format(
                                      "MMM Do YY"
                                    )}
                                  </p>
                                </div>
                              </li>
                              <li>
                                <div className='info-icon'>
                                  <FaUserTie />
                                </div>
                                <div className='info-content'>
                                  <span>Speakers </span>
                                  <p>
                                    {singleEvent?.event_total_speaker}{" "}
                                    Speaker(s)
                                  </p>
                                </div>
                              </li>
                            </ul>
                          </div>
                          <div className='w-50 w-sm-100'>
                            <ul>
                              <li>
                                <div className='info-icon'>
                                  <BiTimeFive />
                                </div>
                                <div className='info-content'>
                                  <span>Time </span>
                                  <p>
                                    {" "}
                                    {moment(
                                      singleEvent?.event_start_time,
                                      "HH:mm:ss"
                                    ).format("hh:mm A")}{" "}
                                    {singleEvent?.event_end_time && (
                                      <>
                                        - {""}
                                        {moment(
                                          singleEvent?.event_end_time,
                                          "HH:mm:ss"
                                        ).format("hh:mm A")}
                                      </>
                                    )}
                                  </p>
                                </div>
                              </li>
                              <li>
                                <div className='info-icon'>
                                  <MdLocationPin />
                                </div>
                                <div className='info-content'>
                                  <span>Venue </span>
                                  <p>{singleEvent?.event_venu}</p>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>

                        {!user.user_fair_member_company_id && (
                          <div className='ceremony-footer'>
                            {loading ? (
                              <button className='default-btn' disabled>
                                Loading
                                <Spinner
                                  as='span'
                                  animation='border'
                                  size='sm'
                                  role='status'
                                  aria-hidden='true'
                                />
                              </button>
                            ) : (
                              <button
                                onClick={handleShow}
                                className='default-btn'
                              >
                                Register Now
                              </button>
                            )}
                            <VisitorModal
                              id={id}
                              show={show}
                              handleClose={handleClose}
                              handleShow={handleShow}
                              setShow={setShow}
                            />
                          </div>
                        )}
                      </Col>
                      <Col
                        md={5}
                        xs={{ span: 12, order: "first" }}
                        className='mt-3 mt-md-0'
                      >
                        <div>
                          <img
                            className='w-100'
                            src={`${imgUrl}asian_tourism_fair/event_files/${singleEvent?.event_photo}`}
                            alt=''
                          />
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <div className='events-matrix-content mt-1'>
                    <div className='events-title events-title-style-2 text-left'>
                      <h1>Details</h1>
                    </div>
                    <div className='events-title event-details-text text-align-justify'>
                      <p>{singleEvent?.event_description}</p>
                    </div>
                  </div>

                  <div className='text-center'>
                    <h3>Speakers</h3>
                  </div>

                  <SingleEventSpeaker id={id} />
                </div>
              }
            </Container>
          </div>
        </Layout>
      }
    </>
  );
};

export default VisitorSingleEventList;
