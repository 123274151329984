import React, { useContext, useReducer, useEffect, useState } from 'react';
import reducer from '../../Reducers/AuthReducer/AuthReducer';
import {
	AUTH_USER_FAILED,
	AUTH_USER_SUCCESS,
	BaseUrl,
} from '../../Helpers/Constant';
import { IAuthContextState } from '../../Types/AuthTypes';
import axios from 'axios';
const initialState: IAuthContextState = {
	user: {},
};

const AuthContext = React.createContext(initialState);

const AuthContextProvider = ({ children }: { children: React.ReactNode }) => {
	const [state, dispatch] = useReducer(reducer, initialState);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		(async () => {
			const token = localStorage.getItem('exhibitor_token');

			if (token) {
				setIsLoading(true);
				try {
					const { data } = await axios.get(
						`${BaseUrl}/api/auth/fair-member/profile`,
						{
							headers: {
								Authorization: `bearer ${token}`,
							},
						}
					);
					dispatch({ type: AUTH_USER_SUCCESS, payload: data.data });
					setIsLoading(false);
				} catch (error) {
					setIsLoading(false);
					dispatch({ type: AUTH_USER_FAILED });
				} finally {
					setIsLoading(false);
				}
			}
		})();
	}, []);

	return (
		<>
			<AuthContext.Provider
				value={{ ...state, dispatch, isLoading, setIsLoading }}
			>
				{children}
			</AuthContext.Provider>
		</>
	);
};

const useAuthContext = () => {
	return useContext(AuthContext);
};

export { AuthContextProvider, useAuthContext };
