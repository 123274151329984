import { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { SubmitHandler, useForm } from 'react-hook-form';
import Toaster from '../../Components/Toaster/Toaster';
import axios from 'axios';
import { BaseUrl } from '../../Helpers/Constant';

export default function SpeakerModal({ id, event_speaker_id, handleClose, show, setMaterial, materials }: any) {
  const Toast = Toaster();
  const [profilePhoto, setProfilePhoto] = useState<any>('');
  const { register, handleSubmit, reset } = useForm<any>();


  const onSubmit: SubmitHandler<any> = async (data) => {
    const fromData = new FormData();
    fromData.append('sm_details', data.sm_details);
    fromData.append('event_speaker_id', event_speaker_id);
    if (profilePhoto) {
      fromData.append('sm_file', profilePhoto);
    } else {
      Toast.fire({
        icon: 'error',
        title: "Please Provide an File",
      });
    }
    const { data: res } = await axios.post(`${BaseUrl}/api/fair/guest-speaker/add-materials`, fromData)

    if (res.success) {
      Toast.fire({
        icon: 'success',
        title: `successfully added`,
      });

      setMaterial([...materials, { sm_id: res.sm_id, sm_details: data.sm_details, sm_file: res.sm_file }])
    } else {
      Toast.fire({
        icon: 'error',
        title: res.message,
      });
    }
    reset()
    setProfilePhoto("");
    handleClose()
  };

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Speaker material</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div>
              <label htmlFor='' className='pb-2'>
                Material Details
              </label>
              <br />

              <textarea
                {...register('sm_details')}
                className='inputFiled'
                id=''
                placeholder='type here'
                cols={50}
                rows={5}
              />
            </div>
            <div className='w-150-100'>
              <div className='companies-logo-card'>
                <div className='img-thumbnail ' style={{ width: '280px' }}>
                  {profilePhoto ? (
                    <img
                      style={{
                        width: '270px',
                        height: '135px',
                      }}
                      className=' ls-is-cached lazyloaded'
                      id='uploadedImage'
                      src={URL.createObjectURL(
                        profilePhoto
                      )}
                      alt=''
                    />
                  ) : (
                    <img
                      style={{
                        width: '270px',
                        height: '135px',
                        objectFit: 'contain',
                      }}
                      className=' ls-is-cached lazyloaded'
                      id='uploadedImage'
                      src='/assets/image.png'
                      alt=''
                    />
                  )}
                </div>
                <div className='pt-4'>
                  <label htmlFor='' className='pb-2'>
                    Upload Files <span className='require'>*</span>
                  </label>
                  <br />
                  <div>
                    <input

                      required
                      type='file'
                      id='actual-btn1'
                      accept='image/jpg, image/png, image/jpeg'
                      hidden
                      onChange={(e: any) => setProfilePhoto(e.target.files[0])}
                    />

                    <label

                      htmlFor='actual-btn1'
                      className='upload-visitor-image-btn text-center'
                    >
                      Upload file
                    </label>
                  </div>
                </div>
              </div>
              <div className='form-text'>
                For Best View Upload Image size 600 X 600px
              </div>
            </div>

            <Button className='mt-5 d-flex order-2 ms-auto' type='submit'>
              Submit
            </Button>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}
