import { useState, useEffect } from "react";
import Layout from "../../Components/Layout/Layout";
import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { BiTimeFive } from "react-icons/bi";
import { IEvent } from "../../Types/EventsType";
import { Link } from "react-router-dom";
import { BaseUrl, imgUrl } from "../../Helpers/Constant";
import { SpeakerAuthContext } from "../../Context/AuthContex/SpeakerAuthContext";
import axios from "axios";
import HeaderBottom from "../../Components/HeaderBottom/HeaderBottom";
import Loader from "../../Components/Loader/Loader";

const EventList = () => {
  const [allEvents, setAllEvents] = useState<IEvent[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [searchValue, setSearchValue] = useState<string>("");
  const { speakerUser } = SpeakerAuthContext();

  useEffect(() => {
    setLoading(true);
    try {
      let url = `/api/fair/event/get/all/event/by/${speakerUser?.guest_speaker_id}`;
      if (searchValue) {
        url = `/api/fair/event/get/${searchValue}/all`;
      }
      (async () => {
        const { data } = await axios.get(`${BaseUrl}${url}`);
        if (data.success && data?.data?.length) {
          setAllEvents(data.data);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })();
    } catch (error) {
      setLoading(false);
    }
    setLoading(false);
  }, [searchValue, speakerUser?.guest_speaker_id]);

  return (
    <>
      {
        <Layout>
          <HeaderBottom pathName={"Speaker Events"} />
          <div
            style={{
              paddingTop: "50px",
              paddingBottom: "50px",
              backgroundColor: "#f5f5f5",
            }}
          >
            <Container>
              {loading ? (
                <Loader />
              ) : (
                <>
                  {allEvents?.length ? (
                    <>
                      <Row sx={12} md={4}>
                        {allEvents?.map((sEvent) => {
                          return (
                            <Col key={sEvent.event_id}>
                              <div className='events-card-bg event_container m-0'>
                                <div className='events-img-sec'>
                                  <Link
                                    to={`/event-list/${sEvent.event_id}?event_sp_id=${sEvent.event_speaker_id}`}
                                  >
                                    <div className='event_wrapper'>
                                      <div className='event_venue ' style={{}}>
                                        <div className='d-flex justify-start'>
                                          <BiTimeFive color='white' />
                                          <span className='ms-2 mt-1'>
                                            {sEvent?.event_date.split("T")[0]}
                                          </span>
                                          <span
                                            style={{
                                              marginLeft: "auto",
                                              order: 2,
                                            }}
                                          >
                                            {sEvent?.event_status}
                                          </span>
                                        </div>
                                      </div>
                                      <img
                                        className='event-img'
                                        style={{
                                          maxHeight: "14rem",
                                          minHeight: "14rem",
                                          objectFit: "cover",
                                        }}
                                        alt=''
                                        src={`${imgUrl}asian_tourism_fair/event_files/${sEvent.event_photo}`}
                                      />
                                    </div>
                                  </Link>
                                </div>
                                <div className='text-center'>
                                  <h3 className='event_title'>
                                    <Link
                                      to={`/event-list/${sEvent.event_id}?event_sp_id=${sEvent.event_speaker_id}`}
                                      className='text-decoration-none text-black'
                                    >
                                      {sEvent.event_title}
                                    </Link>
                                  </h3>
                                </div>
                              </div>
                            </Col>
                          );
                        })}
                      </Row>
                    </>
                  ) : (
                    <p className='text-center fs-4 fw-bold'>No event found</p>
                  )}
                </>
              )}
            </Container>
          </div>
        </Layout>
      }
    </>
  );
};

export default EventList;
