import React from "react";
import Layout from "../../Components/Layout/Layout";

const BuyerRegister = () => {
  return (
    <div>
      <Layout>
        <div
          style={{
            paddingBottom: "50px",
            backgroundColor: "#f5f5f5",
          }}
        >
          dafhauh fiu haiuhfiahgiufiuaghf
        </div>
      </Layout>
    </div>
  );
};

export default BuyerRegister;
