import React, { useContext, useReducer, useEffect, useState } from "react";
import visitorReducer from "../../Reducers/AuthReducer/VisitorReducer";
import {
  AUTH_VISITOR_USER_FAILED,
  AUTH_VISITOR_USER_SUCCESS,
  BaseUrl,
} from "../../Helpers/Constant";
import { IVisitorAuthContext } from "../../Types/VisitorAuthTypes";
import axios from "axios";

const initialState: IVisitorAuthContext | any = {
  visitorUser: {},
};
const VisitorContext = React.createContext(initialState);

const VisitorAuthContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [state, dispatch] = useReducer(visitorReducer, initialState);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        const { data: res } = await axios.get(
          `${BaseUrl}/api/auth/visitor/profile`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        dispatch({ type: AUTH_VISITOR_USER_SUCCESS, payload: res.data });
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        dispatch({ type: AUTH_VISITOR_USER_FAILED });
      }
    })();
  }, []);

  return (
    <VisitorContext.Provider
      value={{ ...state, dispatch, isLoading, setIsLoading }}
    >
      {children}
    </VisitorContext.Provider>
  );
};

const VisitorAuthContext = () => {
  return useContext(VisitorContext);
};

export { VisitorAuthContextProvider, VisitorAuthContext };
