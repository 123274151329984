import React from 'react';
import { Container } from 'react-bootstrap';
import { MdOutlineKeyboardArrowRight } from 'react-icons/md';
const HeaderBottom = ({ pathName }: any) => {
  return (
    <div className='page-banner-area my-5'>
      <Container>
        <div className='text-center text-white'>
          <h1>{pathName}</h1>
          <p className='fw-bold'>
            Home <MdOutlineKeyboardArrowRight /> {pathName}
          </p>
        </div>
      </Container>
    </div>
  );
};

export default HeaderBottom;
