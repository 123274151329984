import { Spinner } from 'react-bootstrap';
import { Navigate } from 'react-router-dom';
import { SpeakerAuthContext } from '../../Context/AuthContex/SpeakerAuthContext';

interface PropType {
    component: JSX.Element;
}
const SpeakerPrivateRoute = ({ component }: PropType) => {
    const { speakerUser, isLoading } = SpeakerAuthContext();
      
    if (isLoading) {
        return (
            <div className='text-center' style={{ marginTop: '200px' }}>
                <Spinner
                    animation='border'
                    variant='primary'
                    style={{ height: '60px', width: '60px' }}
                />
            </div>
        );
    }
    if (speakerUser?.guest_speaker_id) {
        return component;
    } else {
        return <Navigate to='/speaker-login' />;
    }
};

export default SpeakerPrivateRoute;
