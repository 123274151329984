import { useEffect, useState } from "react";
import { TbWorld } from "react-icons/tb";
import axios from "axios";
import { BaseUrl, imgUrl } from "../../Helpers/Constant";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import HeaderBottom from "../../Components/HeaderBottom/HeaderBottom";
import Layout from "../../Components/Layout/Layout";
import Loader from "../../Components/Loader/Loader";

interface IStall {
  fair_stall_name: string;
  fair_stall_hall_no: number;
}

interface IExhibitors {
  user_fair_member_id: number;
  user_fair_member_company_name: string;
  user_fair_member_company_address: string;
  user_fair_member_company_city: string;
  user_fair_member_company_website: string;
  user_fair_member_company_email: string;
  user_fair_member_company_logo: string;
  user_fair_member_category: string;
  stall_info: IStall[];
}

export default function ExhibitorList() {
  const [exhibitors, setExhibitors] = useState<IExhibitors[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    try {
      setLoading(true);
      (async () => {
        const { data } = await axios.get(
          `${BaseUrl}/api/fair/member/fair-member-company/get/approved/ada`
        );
        if (data.success) {
          setExhibitors(data.data);
        }
      })();
    } catch (error) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  }, []);

  return (
    <>
      {loading && <Loader />}
      <Layout>
        <HeaderBottom pathName={"Exhibitor List"} />
        <Container>
          <Row className="g-4 ">
            {exhibitors?.length ? (
              exhibitors?.map((exhibitor: IExhibitors, index: number) => {
                return (
                  <Col xs={12} md={6} lg={4} key={index}>
                    <Link
                      className="text-dark text-decoration-none"
                      to={`/exhibitor-list/${exhibitor.user_fair_member_id}`}
                    >
                      <div
                        className="card p-0 m-0"
                        style={{ maxWidth: "550px" }}
                      >
                        <div className="row g-0">
                          {" "}
                          <div className="col-md-4">
                            <img
                              src={`${imgUrl}asian_tourism_fair/fair_member_files/${exhibitor?.user_fair_member_company_logo}`}
                              className="img-fluid rounded-start"
                              style={{ width: "100%", height: "100%" }}
                              alt="..."
                            />
                          </div>
                          <div
                            className="col-md-8 "
                            style={{
                              boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                            }}
                          >
                            <div className="card-body">
                              <h5 className="card-title fw-bolder">
                                {exhibitor.user_fair_member_company_name}
                              </h5>

                              <p className="card-text d-flex align-items-center  mt-3">
                                <span className="fw-bold">Category:</span>
                                <span
                                  style={{ fontSize: "12px" }}
                                  className=" ms-1"
                                >
                                  {exhibitor?.user_fair_member_category ||
                                    " N/A"}
                                </span>
                              </p>

                              <p className="card-text my-1">
                                <span>
                                  {" "}
                                  <TbWorld className="pr-1" />
                                </span>
                                <span className="ms-1 d-inline-block">
                                  {exhibitor?.user_fair_member_company_website}
                                </span>
                              </p>

                              <div className="d-flex flex-wrap">
                                {exhibitor?.stall_info.map(
                                  (stall: IStall, index: number) => (
                                    <small
                                      className="text-white bg-warning p-1 rounded m-1"
                                      key={index}
                                    >
                                      {stall.fair_stall_name}{" "}
                                      {stall.fair_stall_hall_no}
                                    </small>
                                  )
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </Col>
                );
              })
            ) : (
              <h1 className="text-danger text-center">Exhibitor Not Found</h1>
            )}
          </Row>
        </Container>
      </Layout>
    </>
  );
}
