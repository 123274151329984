import { useState, useEffect } from 'react';
import { SpeakerAuthContext } from '../../Context/AuthContex/SpeakerAuthContext';
import { Col, Container, Row } from 'react-bootstrap';
import Loader from '../Spinner/Loader';
import { BaseUrl, imgUrl } from '../../Helpers/Constant';
import Header from '../Header/Header';
import HeaderBottom from '../HeaderBottom/HeaderBottom';
import Footer from '../Footer/Footer';
import axios from 'axios';
export default function SpeakerProfile() {
    const { speakerUser, isLoading } = SpeakerAuthContext();
    const [speaker, setSpeaker] = useState<any>()


    useEffect(() => {
        try {
            (async () => {
                const { data } = await axios.get(`${BaseUrl}/api/fair/get/single/guest-speaker/guest-speaker-id/${speakerUser?.guest_speaker_id}`);
                if (data.success) {
                    setSpeaker(data.data);

                }
            })();
        } catch (error) {
        }
    }, [speakerUser?.guest_speaker_id])

    return (
        <>
            {isLoading && <Loader />}
            <Header />
            <HeaderBottom pathName={'Speaker Profile'} />
            <Container className='my-5'>
                <Row style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Col sm={12} md={10}>
                        <section style={{ backgroundColor: '#f4f5f7' }}>
                            <div className="container py-5 h-100">
                                <div className="row  d-flex justify-content-center align-items-center ">

                                    <div className="card mb-3" style={{ borderRadius: ".5rem", height: 'auto', width: '50rem' }}>
                                        <div className="row">
                                            <div className="col-md-5 gradient-custom text-center text-white p-0 border border-2"
                                                style={{ borderTopLeftRadius: ".5rem", borderBottomLeftRadius: ".5rem" }}>
                                                <img src={`${imgUrl}asian_tourism_fair/guest-speaker-files/${speaker?.guest_speaker_photo}`}
                                                    alt="Avatar" className="img-fluid  mb-2  " style={{ width: "500px" }} />
                                                <h5 className='text-black'>{speaker?.guest_speaker_name}</h5>
                                                <small className="text-muted fw-bold">{speakerUser?.guest_speaker_email}</small><br />
                                                <small className='text-muted  fw-bold'>{speaker?.guest_speaker_designation}</small><br />
                                                <small className='text-muted  fw-bold'>{speaker?.guest_speaker_company_name}</small> <br />
                                                <i className="far fa-edit mb-5"></i>
                                            </div>
                                            <div className="col-md-7">
                                                <div className="card-body p-1 pt-3">
                                                    <h5 className='fw-bold'>About</h5>
                                                    <hr className="mt-0 mb-4" />
                                                    <div className="row pt-1">
                                                        <div className="mb-2 px-4">
                                                            <p className="text-muted">{speaker?.guest_speaker_details}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </section>
                    </Col>
                </Row>
            </Container>
            <Footer />
        </>
    )
}
