import { useState, useEffect } from "react";
import Layout from "../../Components/Layout/Layout";
import HeaderBottom from "../../Components/HeaderBottom/HeaderBottom";
import Toaster from "../../Components/Toaster/Toaster";
import { useNavigate } from "react-router-dom";
import { SubmitHandler, useForm } from "react-hook-form";
import {
  AUTH_SPEAKER_USER_FAILED,
  AUTH_SPEAKER_USER_SUCCESS,
  BaseUrl,
} from "../../Helpers/Constant";

import { Button } from "react-bootstrap";
import { SpeakerAuthContext } from "../../Context/AuthContex/SpeakerAuthContext";
import axios from "axios";

interface inputForm {
  email: string;
  password: string;
}
const SpeakerLogin = () => {
  const {
    dispatch: authDispatch,

    setIsLoading,
  } = SpeakerAuthContext();
  const Toast = Toaster();
  const navigate = useNavigate();
  const { register, handleSubmit } = useForm<inputForm>();
  const [passwordType, setPasswordType] = useState("password");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const onSubmit: SubmitHandler<inputForm> = async (data) => {
    try {
      setIsLoading?.(true);
      const { data: res } = await axios.post(
        `${BaseUrl}/api/auth/speaker/login`,
        data
      );

      if (res.success) {
        Toast.fire({
          icon: "success",
          title: `successfully login`,
        });
        localStorage.setItem("speaker_token", res.token);

        authDispatch?.({
          type: AUTH_SPEAKER_USER_SUCCESS,
          payload: res.data,
        });
        // no dispatch
        navigate("/");
        setIsLoading?.(false);
      } else {
        setIsLoading?.(false);
        authDispatch?.({ type: AUTH_SPEAKER_USER_FAILED });
      }
    } catch (err: any) {
      Toast.fire({
        icon: "error",
        title: err?.response?.data.message,
      });
      authDispatch?.({ type: AUTH_SPEAKER_USER_FAILED });
    }

    setIsLoading?.(false);
  };
  return (
    <Layout>
      <HeaderBottom pathName={"Speaker Login"} />
      <div className='login-wrapper'>
        <div className='login-div'>
          <div className='visitor-login-page'>
            <div className='text-center mt-3'>
              <p className='fw-bold fs-3'>Speaker Login</p>
            </div>
            <div className='login-form'>
              {/* <div className='text-end '>
                <Link
                  to='/speaker-registration'
                  className='text-decoration-none'
                >
                  Create an Account
                </Link>
              </div> */}
              <form onSubmit={handleSubmit(onSubmit)}>
                <div>
                  <label htmlFor='' className='pb-2'>
                    Enter your email
                  </label>
                  <br />
                  <input
                    placeholder='Enter your email address'
                    className='inputFiled'
                    {...register("email")}
                    type='text'
                  />
                </div>
                <div className='pt-4'>
                  <label htmlFor='' className='pb-2'>
                    Enter your password
                  </label>
                  <br />
                  <input
                    placeholder='Enter your password'
                    {...register("password")}
                    className='inputFiled'
                    type={passwordType}
                  />
                </div>
                <div className=' pt-3 d-flex align-items-center justify-content-between'>
                  <div
                    className=' d-flex align-items-center'
                    style={{ cursor: "pointer" }}
                  >
                    <input
                      style={{ cursor: "pointer" }}
                      type='checkbox'
                      onClick={() => {
                        passwordType === "password"
                          ? setPasswordType("text")
                          : setPasswordType("password");
                      }}
                    />
                    <p className='ps-2'>Show password</p>
                  </div>
                </div>

                <div>
                  {/* {isLoading ? (
                    <Loader css={'w-100 mt-5 text-white'} />
                  ) : (
                   
                  )} */}
                  <Button type='submit' className='w-100 mt-5 submit-button'>
                    Login
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default SpeakerLogin;
