import { Button, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { VisitorAuthContext } from "../../Context/AuthContex/VisitorAuthContext";
import Toaster from "../../Components/Toaster/Toaster";
import axios from "axios";
import { BaseUrl } from "../../Helpers/Constant";
import { useState } from "react";

export default function VisitorModal({
  show,
  handleClose,
  checkStudent,
  cart,
  subTotal,
  handelCloseSidebar,
  setCart,
  purchase,
  setPurchase,
}: any) {
  const { visitorUser } = VisitorAuthContext();
  const { register, handleSubmit, reset } = useForm();
  // const [tID, setTID] = useState({
  //   joinID: 0,
  //   invoiceId: 0,
  // });

  const [refid, setRefID] = useState();

  const [loading, setLoading] = useState<boolean>(false);

  const item = cart?.map((sCart: any) => {
    return {
      id: sCart?.event_id,
      name: sCart?.event_title,
      price: sCart?.price,
    };
  });

  const matchEvent = purchase.map((obj1: any) => {
    const matchedObj2 = cart.find(
      (obj2: any) => obj1.event_id === obj2.event_id
    );
    if (matchedObj2) {
      return { ...obj1, joined: true };
    }
    return obj1;
  });

  // console.log(updatedArray1);

  const onSubmit = async (data: any) => {
    setLoading(true);

    let registerData: any = {
      // visitor_type: checkStudent ? "student-visitor" : "regular-visitor",
      seminarItem: item,
      // total_amount: subTotal,
      visitor_id: visitorUser?.visitor_id,
      visitor_organization: data.visitor_organization,
      visitor_designation: data.visitor_designation,
      visitor_phone: visitorUser?.visitor_phone
        ? visitorUser?.visitor_phone
        : data.visitor_phone,
    };
    if (checkStudent && refid) {
      registerData.refId = refid;
    }

    try {
      const { data: res } = await axios.post(
        `${BaseUrl}/api/fair/event/join`,
        registerData
      );

      if (res.success) {
        Toaster().fire({
          icon: "success",
          title: res.message,
        });
        // setTID({
        //   joinID: res.data.id,
        //   invoiceId: res.data.visitor_invoice_id,
        // });
        setPurchase(matchEvent);
        localStorage.removeItem("seminar_item");
        setCart([]);
        handleClose();
        handelCloseSidebar();
        reset();
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (err: any) {
      Toaster().fire({
        icon: "error",
        title: err?.response?.data.message,
      });
      setLoading(false);
    }
  };
  return (
    <Modal
      style={{ height: "500px" }}
      show={show}
      onHide={handleClose}
      keyboard={false}
    >
      {/* {!tID.joinID ? (
        <> */}
      <Modal.Header closeButton>
        <Modal.Title>Register For Event</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="register-top-form-card">
            <div className="row g-3">
              {checkStudent && (
                <div className="col-12 col-sm-12 col-md-12">
                  <div className="row">
                    <label>Reference</label>
                    <div className="w-150-100">
                      <input
                        required
                        type="text"
                        onChange={(e: any) => setRefID(e.target.value)}
                        className="form-control"
                        placeholder="ex: jx93r6"
                      />
                    </div>
                  </div>
                </div>
              )}
              <div className="col-12 col-sm-12 col-md-12">
                <div className="row">
                  <label>Organization</label>
                  <div className="w-150-100">
                    <input
                      required
                      type="text"
                      {...register("visitor_organization")}
                      className="form-control"
                      placeholder="ex: meta"
                    />
                  </div>
                </div>
              </div>
              {!visitorUser?.visitor_phone && (
                <div className="col-12 col-sm-12 col-md-12">
                  <div className="row">
                    <label>Phone</label>
                    <div className="w-150-100">
                      <input
                        required
                        type="text"
                        maxLength={11}
                        {...register("visitor_phone")}
                        className="form-control"
                        placeholder="ex: 01xxxxxx"
                      />
                    </div>
                  </div>
                </div>
              )}
              <div className="col-12 col-sm-12 col-md-12">
                <div className="row">
                  <label>Designation</label>
                  <div className="w-150-100">
                    <input
                      required
                      type="text"
                      {...register("visitor_designation")}
                      className="form-control"
                      placeholder="EX: CEO"
                    />
                  </div>
                </div>
              </div>
              <div>
                {loading ? (
                  <Button disabled className="w-100  submit-button ">
                    Loading...
                  </Button>
                ) : (
                  <Button type="submit" className="w-100  submit-button ">
                    Submit
                  </Button>
                )}
              </div>
            </div>
          </div>
        </form>
      </Modal.Body>
      {/* </>
      ) : (
        <BankInformationModal
          tID={tID}
          handleClose={handleClose}
          handelCloseSidebar={handelCloseSidebar}
          setCart={setCart}
          setTID={setTID}
          purchase={purchase}
          setPurchase={setPurchase}
          cart={cart}
        />
      )} */}
    </Modal>
  );
}
