import { useState, useEffect } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { TbCurrencyTaka } from "react-icons/tb";
import Layout from "./Layout/Layout";
import HeaderBottom from "./HeaderBottom/HeaderBottom";
import { BaseUrl, imgUrl } from "../Helpers/Constant";
import unfill_Heart from "../img/heart.png";
import fill_Heart from "../img/fill-love.png";
import { VisitorAuthContext } from "../Context/AuthContex/VisitorAuthContext";
import Toaster from "./Toaster/Toaster";
import axios from "axios";
import { useParams } from "react-router-dom";

interface IProduct {
  fair_member_product_id: number;
  fair_member_product_name: string;
  fair_member_product_price: number;
  fair_member_special_product_price: number;
  fair_member_product_category: string;
  fair_member_product_url: string;
  fair_member_product_description: string;
  fair_member_product_photo: string[];
  user_fair_member_product_status: string;
  user_fair_member_company_name: string;
  wishlist: number;
  user_fair_member_product_type?: string;
}

export default function VisitorProductAndService() {
  const { id } = useParams();
  const { visitorUser } = VisitorAuthContext();
  const [products, setProducts] = useState<IProduct[]>([]);
  const [isLoading, setLoading] = useState<boolean>(false);
  const formData = new FormData();
  const Toast = Toaster();

  useEffect(() => {
    setLoading(true);
    try {
      (async () => {
        const { data } = await axios.get(
          `${BaseUrl}/api/fair/get/all/fair-member-product/by/pname/all/category/all/memberName/M360ICT/status/approved?visitor_id=${visitorUser?.visitor_id}`
        );

        if (data.success) {
          setProducts(data.data);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })();
    } catch (err) {
      setLoading(false);
    }
  }, [id, visitorUser?.visitor_id]);

  const handleOnClick = async (id: any) => {
    formData.append("products", JSON.stringify([id]));
    formData.append("visitor_id", visitorUser?.visitor_id);
    const filterData = products.filter(
      (product) => product.fair_member_product_id === id
    );

    if (filterData[0].wishlist) {
      const { data } = await axios.delete(
        `${BaseUrl}/api/fair/lead/remove-product-from-wishlist?visitor_id=${visitorUser.data?.visitor_id}&p_id=${id}`
      );
      if (data.success) {
        filterData[0].wishlist = 0;
        const newData = products.map((sProduct) =>
          sProduct.fair_member_product_id === id ? filterData[0] : sProduct
        );
        setProducts(newData);
        Toast.fire({
          icon: "success",
          title: `Successfully removed from wishlist`,
        });
      }
    } else {
      const { data } = await axios.post(
        `${BaseUrl}/api/fair/lead/add-wishlist`,
        formData
      );
      if (data.success) {
        filterData[0].wishlist = 1;
        const newData = products.map((sProduct) =>
          sProduct.fair_member_product_id === id ? filterData[0] : sProduct
        );
        setProducts(newData);
        Toast.fire({
          icon: "success",
          title: `Successfully added to wishlist`,
        });
      }
    }
  };

  return (
    <>
      {!isLoading && (
        <Layout>
          <HeaderBottom pathName={"Item & Service List"} />
          <Container>
            <Row className="g-3">
              {products?.length ? (
                products?.map((product: IProduct) => (
                  <Col
                    xs={12}
                    md={4}
                    lg={3}
                    key={product.fair_member_product_id}
                  >
                    <Card style={{ minHeight: "20rem", maxHeight: "20rem" }}>
                      <div
                        style={{
                          position: "relative",
                          maxHeight: "180px",
                          minHeight: "180px",
                          display: "flex",
                          justifyContent: "end",
                        }}
                      >
                        <button
                          onClick={() =>
                            handleOnClick(product?.fair_member_product_id)
                          }
                          style={{
                            position: "absolute",
                            margin: "3px",
                            backgroundColor: "#eeeeee",
                            border: "none",
                          }}
                          className="rounded px-2 py-1"
                        >
                          {product?.wishlist ? (
                            <img
                              style={{ width: 25 }}
                              src={fill_Heart}
                              alt=""
                            />
                          ) : (
                            <img
                              style={{ width: 25 }}
                              src={unfill_Heart}
                              alt=""
                            />
                          )}
                        </button>
                        {/* card image  */}

                        {product?.fair_member_product_photo?.[0] ? (
                          <img
                            src={`${imgUrl}asian_tourism_fair/fair_files/${
                              product?.fair_member_product_photo?.length &&
                              product?.fair_member_product_photo[0]
                            }`}
                            style={{ maxHeight: "100%", objectFit: "cover" }}
                            className="card-img-top"
                            alt=""
                          />
                        ) : (
                          <img
                            src={"/blank-image.png"}
                            style={{ maxHeight: "100%", objectFit: "cover" }}
                            className="card-img-top"
                            alt=""
                          />
                        )}
                      </div>
                      <div className="card-body">
                        <h5 className="card-title fw-bold">
                          {product?.fair_member_product_name}
                        </h5>
                        <p className="card-text">
                          {product?.fair_member_product_description?.slice(
                            0,
                            50
                          )}
                          ...
                        </p>

                        {product?.fair_member_product_price && (
                          <small className="card-text d-block">
                            <TbCurrencyTaka />
                            {product?.fair_member_product_price}
                          </small>
                        )}
                        {product?.user_fair_member_product_type && (
                          <small className="card-text fw-bold">
                            #{product?.user_fair_member_product_type}
                          </small>
                        )}
                        {product?.fair_member_product_category && (
                          <small className="card-text fw-bold">
                            #{product?.fair_member_product_category}
                          </small>
                        )}
                      </div>
                    </Card>
                  </Col>
                ))
              ) : (
                <h4 className="text-danger text-center fw-bold my-5">
                  No Service Found!
                </h4>
              )}
            </Row>
          </Container>
        </Layout>
      )}
    </>
  );
}
