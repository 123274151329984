// export const BaseUrl: string = "http://192.168.0.234:5003";
// export const BaseUrl: string = "http://192.168.0.240:5003";
export const BaseUrl: string = "https://atf-server.queenconnect.world";

export const imgUrl: string = "https://m360ict.s3.ap-south-1.amazonaws.com/";

//======== Auth context helper ========//
export const SET_SIGNUP_DATA = "SET_SIGNUP_DATA";
export const AUTH_USER_SUCCESS = "AUTH_USER_SUCCESS";
export const AUTH_USER_FAILED = "AUTH_USER_FAILED";
export const USER_PROFILE_UPDATE = "USER_PROFILE_UPDATE";
export const VISITOR_PROFILE_IMAGE_UPDATE = "VISITOR_PROFILE_IMAGE_UPDATE";
export const SPEAKER_PROFILE_IMAGE_UPDATE = "SPEAKER_PROFILE_IMAGE_UPDATE";
//======== visitor Auth context helper ========//

export const AUTH_VISITOR_USER_SUCCESS = "AUTH_VISITOR_USER_SUCCESS";
export const AUTH_VISITOR_USER_FAILED = "AUTH_VISITOR_USER_FAILED";

//======== speaker Auth context helper ========//

export const AUTH_SPEAKER_USER_SUCCESS = "AUTH_SPEAKER_USER_SUCCESS";
export const AUTH_SPEAKER_USER_FAILED = "AUTH_SPEAKER_USER_FAILED";
