
import Layout from '../../Components/Layout/Layout';
import tourImage from '../../img/tour-img.jpg';

const Home = () => {

  
  return (
		<div className='min-vh-100'>
			{
				<Layout>
					<div
						className='p-5 text-center bg-image d-flex justify-content-center align-items-center'
						style={{
							backgroundImage: `url(${tourImage})`,
							filter: 'brightness(75%)',
							height: 700,
							backgroundRepeat: 'no-repeat',
							backgroundSize: 'cover',
							fontSize: '10rem',
						}}
					>
						<div className='mask '>
							<div className=' '>
								<div className='text-dark'>
									<h1
										style={{ fontSize: '80px', color: '#ffc107' }}
										className='mb-3'
									>
										Asian Tourism Fair-2023
									</h1>
								</div>
							</div>
						</div>
					</div>
				</Layout>
			}
		</div>
	);
};

export default Home;
// https://mdbootstrap.com/img/new/slides/041.webp