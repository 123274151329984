import { useState, useEffect } from "react";
import Layout from "../../Components/Layout/Layout";
import HeaderBottom from "../../Components/HeaderBottom/HeaderBottom";
import { VisitorAuthContext } from "../../Context/AuthContex/VisitorAuthContext";
import Toaster from "../../Components/Toaster/Toaster";
import { useNavigate } from "react-router-dom";
import { SubmitHandler, useForm } from "react-hook-form";
import {
  AUTH_VISITOR_USER_FAILED,
  AUTH_VISITOR_USER_SUCCESS,
  BaseUrl,
} from "../../Helpers/Constant";

import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import Loader from "../../Components/Spinner/Loader";
import axios from "axios";

interface inputForm {
  visitor_phoneOrEmail: string;
  password: string;
}
const VisitorRegister = () => {
  const { dispatch: authDispatch } = VisitorAuthContext();
  const Toast = Toaster();
  const navigate = useNavigate();
  const { register, handleSubmit } = useForm<inputForm>();
  const [passwordType, setPasswordType] = useState("password");
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onSubmit: SubmitHandler<inputForm> = async (data) => {
    try {
      setLoading?.(true);
      const { data: res } = await axios.post(
        `${BaseUrl}/api/auth/visitor/login`,
        data
      );

      if (res.success) {
        Toast.fire({
          icon: "success",
          title: `successfully login`,
        });

        authDispatch?.({
          type: AUTH_VISITOR_USER_SUCCESS,
          payload: res.data,
        });
        navigate("/visitor/profile");
        localStorage.setItem("token", res.token);
        setLoading?.(false);
      } else {
        Toast.fire({
          icon: "error",
          title: res.message,
        });
        setLoading?.(false);
        authDispatch?.({ type: AUTH_VISITOR_USER_FAILED });
      }
    } catch (error: any) {
      Toast.fire({
        icon: "error",
        title: error.response.data?.message,
      });
      authDispatch?.({ type: AUTH_VISITOR_USER_FAILED });
      setLoading?.(false);
    }
  };
  return (
    <Layout>
      <HeaderBottom pathName={"Visitor Login"} />
      <div className="login-wrapper">
        <div className="login-div">
          <div className="visitor-login-page">
            <div className="text-center mt-3">
              <p className="fw-bold fs-3">Visitor Login</p>
            </div>

            <div className="login-form">
              <div className="text-end ">
                <Link
                  to="/visitor-registration"
                  className="text-decoration-none"
                >
                  Create an Account
                </Link>
              </div>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div>
                  <label htmlFor="" className="pb-2">
                    Enter your email
                  </label>
                  <br />
                  <input
                    placeholder="Enter your email address"
                    className="inputFiled"
                    {...register("visitor_phoneOrEmail")}
                    type="text"
                  />
                </div>
                <div className="pt-4">
                  <label htmlFor="" className="pb-2">
                    Enter your password
                  </label>
                  <br />
                  <input
                    placeholder="Enter your password"
                    {...register("password")}
                    className="inputFiled"
                    type={passwordType}
                  />
                </div>
                <div className=" pt-3 d-flex align-items-center justify-content-between">
                  <div
                    className=" d-flex align-items-center"
                    style={{ cursor: "pointer" }}
                  >
                    <input
                      style={{ cursor: "pointer" }}
                      type="checkbox"
                      onClick={() => {
                        passwordType === "password"
                          ? setPasswordType("text")
                          : setPasswordType("password");
                      }}
                    />
                    <p className="ps-2">Show password</p>
                  </div>
                  <div className="lost-your-password">
                    <Link to="/visitor-forget-password" className="forget-link">
                      Lost your password?
                    </Link>
                  </div>
                </div>

                <div>
                  {loading ? (
                    <Loader css={"w-100 mt-5 text-white"} />
                  ) : (
                    <Button type="submit" className="w-100 mt-5 submit-button">
                      Login
                    </Button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default VisitorRegister;
