import { useEffect, useState } from "react";
import { VisitorAuthContext } from "../../Context/AuthContex/VisitorAuthContext";
import Toaster from "../../Components/Toaster/Toaster";
import { Table } from "react-bootstrap";
import { BaseUrl, imgUrl } from "../../Helpers/Constant";
import axios from "axios";

const VisitorWishlist = () => {
  const { visitorUser } = VisitorAuthContext();
  const [products, setProducts] = useState<any>();
  const Toast = Toaster();

  useEffect(() => {
    (async () => {
      const { data } = await axios.get(
        `${BaseUrl}/api/fair/visitor/wish-list/${visitorUser?.visitor_id}?`
      );
      if (data.success) {
        setProducts(data?.data);
      }
    })();
  }, [visitorUser?.visitor_id]);

  const handleOnClick = async (id: any) => {
    const { data: res } = await axios.delete(
      `${BaseUrl}/api/fair/lead/remove-product-from-wishlist?visitor_id=${visitorUser?.data.visitor_id}&p_id=${id}`
    );

    if (res.success) {
      const filteredData = products?.filter(
        (product: any) => product.fair_member_product_id !== id
      );
      setProducts(filteredData);
      Toast.fire({
        icon: "success",
        title: `Successfully removed from wishlist`,
      });
    }
  };

  return (
    <>
      <div className="profile-right-side px-3 py-3">
        <h4 className="component-tittle">Wishlist</h4>
        <div className="profile-right-info pt-3 ps-2">
          <p className="text-center ">
            <div className="profile-right-info pt-3 ps-2">
              {products?.length ? (
                <Table bordered responsive className="">
                  <thead>
                    <tr className="fw-bold">
                      <th>Image</th>
                      <th>Name</th>
                      <th>Organization</th>
                      <th>Price</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody className="">
                    {products?.map((product: any) => (
                      <tr>
                        <td>
                          <img
                            width={50}
                            height={60}
                            src={`${imgUrl}asian_tourism_fair/fair_files/${
                              product?.fair_member_product_photo?.length &&
                              product?.fair_member_product_photo[0]
                            }`}
                            alt=""
                          />
                        </td>
                        <td>{product.fair_member_product_name}</td>
                        <td>
                          {product.user_fair_member_company_name || "N/A"}
                        </td>
                        <td>{product.fair_member_product_price}</td>
                        <td
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            handleOnClick(product?.fair_member_product_id)
                          }
                          className="text-danger"
                        >
                          <span>Remove</span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              ) : (
                <h5 className="text-danger my-3 text-center">Empty wishlist</h5>
              )}
            </div>
          </p>
        </div>
      </div>
    </>
  );
};

export default VisitorWishlist;
