import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { useAuthContext } from "../../Context/AuthContex/AuthContex";
import { Button } from "react-bootstrap";
import "./header.css";
import {
  // AUTH_SPEAKER_USER_FAILED,
  // AUTH_USER_FAILED,
  AUTH_VISITOR_USER_FAILED,
} from "../../Helpers/Constant";
import { VisitorAuthContext } from "../../Context/AuthContex/VisitorAuthContext";
import { SpeakerAuthContext } from "../../Context/AuthContex/SpeakerAuthContext";
import Loader from "../Loader/Loader";

const Header = () => {
  const {
    user,
    // dispatch: authDispatch,
    isLoading: exhibitorLoading,
  } = useAuthContext();
  const {
    visitorUser,
    dispatch: VisitorAuthDispatch,
    isLoading: visitorLoading,
  } = VisitorAuthContext();
  const {
    speakerUser,
    // dispatch: speakerAuthDispatch,
    isLoading: speakerLoading,
  } = SpeakerAuthContext();

  const handleLogout = () => {
    localStorage.removeItem("token");
    // localStorage.removeItem("exhibitor_token");
    // localStorage.removeItem("speaker_token");
    // authDispatch?.({
    //   type: AUTH_USER_FAILED,
    // });
    VisitorAuthDispatch?.({
      type: AUTH_VISITOR_USER_FAILED,
    });
    // speakerAuthDispatch?.({
    //   type: AUTH_SPEAKER_USER_FAILED,
    // });
  };

  if (user?.user_fair_member_id && exhibitorLoading) {
    return <Loader />;
  }
  if (visitorUser?.visitor_id && visitorLoading) {
    return <Loader />;
  }
  if (speakerUser?.guest_speaker_id && speakerLoading) {
    return <Loader />;
  }

  return (
    <>
      <Container fluid className="custom-container">
        <Navbar
          collapseOnSelect
          expand="lg"
          variant="dark"
          style={{ backgroundColor: "#fff" }}
        >
          <Link to="/">
            <img
              src="\assets\atf-logo.png"
              alt=""
              width={70}
              height={70}
              style={{ objectFit: "contain" }}
            />
          </Link>

          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav ">
            <Nav className="ms-auto">
              <div className="header-navigation mt-4">
                <ul className="main-menu">
                  <li className="active-menu">
                    <Link to="/">Home</Link>
                  </li>
                  {/* 
                  {visitorUser?.visitor_id && (
                    <li>
                      <Link to="/exhibitor-list">Exhibitor List</Link>
                    </li>
                  )} */}
                  <li>
                    {speakerUser?.guest_speaker_id && (
                      <ul>
                        <li className="active-menu">
                          <Link to="/event-list">Event List</Link>
                        </li>
                      </ul>
                    )}
                  </li>
                  {/* <li>
										{!speakerUser?.guest_speaker_id && (
											<ul>
												<li className=''>
													<Link to='/visitor-event-list'>Event adf List</Link>
												</li>
											</ul>
										)}
									</li> */}
                  {/* {!speakerUser.guest_speaker_id && ( */}
                  <li>
                    <Link to="/seminar-list">Seminar List</Link>
                  </li>
                  <li>
                    <Link to="/raffle-draw">Raffle Draw</Link>
                  </li>
                  {/* )} */}
                </ul>
              </div>
            </Nav>
            <Nav className="ms-auto">
              {visitorUser?.visitor_id ||
              user?.user_fair_member_id ||
              speakerUser?.guest_speaker_id ? (
                <>
                  <Link
                    to={
                      visitorUser?.visitor_id
                        ? "/visitor/profile"
                        : speakerUser?.guest_speaker_id
                        ? "/speaker"
                        : "/profile"
                    }
                    className="text-white text-decoration-none my-3"
                  >
                    <Button className="btn btn-3 btn-info me-2 ">
                      Profile
                    </Button>
                  </Link>

                  <Button
                    onClick={handleLogout}
                    className="btn btn-3 btn-primary  my-3"
                  >
                    Logout
                  </Button>
                </>
              ) : (
                <>
                  <div className="header-btn  pe-3">
                    <Link
                      to="/visitor-registration"
                      className="text-decoration-none text-black"
                    >
                      <Button className="btn-secondary ">Registration</Button>
                    </Link>

                    {/* <Dropdown className="pe-2">
                      <Dropdown.Toggle
                        className="btn-secondary "
                        id="dropdown-basic"
                      >
                        Registration
                      </Dropdown.Toggle>

                      <Dropdown.Menu
                        align="start"
                        className="p-2 "
                        style={{ height: "100px" }}
                      >
                        <div>
                          <Link
                            to="/book-your-space"
                            className="text-decoration-none text-black my-5"
                          >
                            Exhibitor registration
                          </Link>
                        </div>
                        <div>
                          <Link
                            to="/visitor-registration"
                            className="text-decoration-none text-black"
                          >
                            Visitor registration
                          </Link>
                        </div>
                      </Dropdown.Menu>
                    </Dropdown> */}
                  </div>
                  <div className="header-btn st-2 mt-2 mt-md-0 ">
                    <Link
                      to="/visitor-login"
                      className="text-decoration-none text-black"
                    >
                      <Button className="btn-info">Login</Button>
                    </Link>

                    {/* <Dropdown className="pe-3">
                      <Dropdown.Toggle className="btn-info" id="dropdown-basic">
                        Login
                      </Dropdown.Toggle>

                      <Dropdown.Menu align="start" className="p-2">
                        <div className="pb-2">
                          <Link
                            to="/login"
                            className="text-decoration-none text-black "
                          >
                            Exhibitor login
                          </Link>
                        </div>

                        <div className="text-center">
                          <Link
                            to="/visitor-login"
                            className="text-decoration-none text-black"
                          >
                            Visitor login
                          </Link>
                        </div>
                        <div>
                          <Link
                            to="/speaker-login"
                            className="text-decoration-none text-black"
                          >
                            Speaker login
                          </Link>
                        </div>
                      </Dropdown.Menu>
                    </Dropdown> */}
                  </div>
                </>
              )}
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </Container>
    </>
  );
};

export default Header;
