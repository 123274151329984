import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../src/Styles/Header.css';
import '../src/Styles/Button.css';
import '../src/Styles/Login-Reg.css';
import '../src/Styles/Footer.css';
import '../src/Styles/Profile.css';
import '../src/Styles/VisitorProfile.css';
import '../src/Styles/EventList.css';
import { RouterProvider } from 'react-router-dom';
import { routers } from './Utils/Routers';
import { AiFillFacebook, AiFillInstagram, AiFillTwitterCircle, AiFillYoutube, AiOutlineMail } from 'react-icons/ai';
import { BsPinterest } from 'react-icons/bs';
function App() {
  return (
    <>
      { <div>
        <div className='social-links' style={{ backgroundColor: "#FFCA04" }}>
          <span>
            <a href="https://www.facebook.com/asiantourismfair/" ><AiFillFacebook /></a>
            <a href="https://www.youtube.com/channel/UCApHfu-CzaqjJ3s5MeYu5dA/featured" ><AiFillYoutube /></a>
            <a href="#" ><AiOutlineMail /></a>
            <a href="https://www.instagram.com/asiantourismfair/" ><AiFillInstagram /></a>
            <a href="https://twitter.com/i/flow/login?redirect_after_login=%2FATFDhaka" ><AiFillTwitterCircle /></a>
            <a href="https://www.pinterest.com/asiantourismfair/" ><BsPinterest /></a></span>
        </div>
        <RouterProvider router={routers} />
      </div>}

    </>
  );
}

export default App;
