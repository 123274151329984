import { useState, useEffect } from "react";
import { VisitorAuthContext } from "../../../Context/AuthContex/VisitorAuthContext";
import { IVisitorUser } from "../../../Types/VisitorAllTypes";
import { useForm } from "react-hook-form";
import Toaster from "../../Toaster/Toaster";
import { Button } from "react-bootstrap";
import Loader from "../../Spinner/Loader";
import { IoIosArrowBack } from "react-icons/io";
import axios from "axios";
import { BaseUrl } from "../../../Helpers/Constant";
import { FiEdit } from "react-icons/fi";
import moment from "moment";

const VisitorProfileHomePage = () => {
  const { visitorUser } = VisitorAuthContext();
  const [user, setUser] = useState<IVisitorUser>();
  const [toggle, setToggle] = useState(true);
  const Toast = Toaster();

  const { register, handleSubmit } = useForm();
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState("");

  useEffect(() => {
    (async () => {
      const { data } = await axios.get(
        `${BaseUrl}/api/fair/visitor/get/single/visitor/${visitorUser?.visitor_id}`
      );
      if (data.success) {
        setUser(data.data);
      }
    })();
  }, [toggle, visitorUser?.visitor_id]);

  const onSubmit = async (data: any) => {
    setLoading(true);
    data.visitor_id = visitorUser?.visitor_id;
    data.visitor_date_of_birth = date;
    const formData = new FormData();
    Object.keys(data).forEach((item) => {
      if (data[item]) {
        formData.append(item, data[item]);
      }
    });

    try {
      const { data: res } = await axios.put(
        `${BaseUrl}/api/fair/visitor/visitor-info-update`,
        formData
      );

      if (res.success) {
        Toast.fire({
          icon: "success",
          title: res.message,
        });
        setUser({ ...user, ...data });
        setLoading(false);
        setToggle(!toggle);
        window.scrollTo(0, 0);
      } else {
        Toast.fire({
          icon: "error",
          title: res.message,
        });
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <div>
      {toggle ? (
        <div className="profile-right-side px-3 py-3">
          <div className="d-flex  align-items-center justify-content-between">
            <h4 className="component-tittle">Profile Information</h4>
            <div className="mb-5">
              <div
                onClick={() => setToggle(!toggle)}
                style={{
                  backgroundColor: "#1982c3",
                  color: "white",
                  width: "65px",
                  padding: "5px 7px",
                  borderRadius: "5px",
                }}
                className="pointer"
              >
                <p>
                  Edit <FiEdit />
                </p>
              </div>
            </div>
          </div>
          <div className="profile-right-info pt-3 ps-2">
            <ul>
              <div className="section-title-cs edit-sec-cs ">
                <h4 className="profile-title mb-4"> Personal Info.</h4>

                <div className="row">
                  <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 mb-3">
                    <li>
                      <span>Name</span> :{" "}
                      <p className="">
                        {visitorUser?.visitor_first_name}{" "}
                        {visitorUser?.visitor_last_name}
                      </p>
                    </li>

                    {user?.visitor_gender && (
                      <li>
                        <span>Gender</span> : <p>{user?.visitor_gender}</p>
                      </li>
                    )}
                    {user?.visitor_organization && (
                      <li>
                        <span>Organization</span> :{" "}
                        <p>{user?.visitor_organization}</p>
                      </li>
                    )}
                    {user?.visitor_designation && (
                      <li>
                        <span>Designation</span> :{" "}
                        <p>{user?.visitor_designation}</p>
                      </li>
                    )}
                    {user?.visitor_profession && (
                      <li>
                        <span>Profession</span> :{" "}
                        <p>{user?.visitor_profession}</p>
                      </li>
                    )}
                    {user?.visitor_date_of_birth && (
                      <li>
                        <span>Date Of Birth</span> :{" "}
                        <p>
                          {moment(user.visitor_date_of_birth).format(
                            "MMM Do YY"
                          )}
                        </p>
                      </li>
                    )}
                  </div>
                </div>
                {user?.visitor_address && (
                  <div className="row">
                    <div className="section-title-cs edit-sec-cs ">
                      <h4 className="profile-title mb-4">Address & Location</h4>
                      {user?.visitor_address && (
                        <li>
                          <span>Address</span> : <p>{user?.visitor_address}</p>
                        </li>
                      )}
                      {user?.visitor_city && (
                        <li>
                          <span>City</span> : <p>{user?.visitor_city}</p>
                        </li>
                      )}
                      {user?.visitor_zip_code && (
                        <li>
                          <span>Zip</span> : <p>{user?.visitor_zip_code}</p>
                        </li>
                      )}
                    </div>
                  </div>
                )}
                <div className="row">
                  {/* Social links  */}
                  <div className="section-title-cs edit-sec-cs">
                    <h4 className="profile-title mb-4">
                      {" "}
                      Social Links & Contact
                    </h4>

                    <div className="row">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 mb-3">
                        {user?.visitor_facebook && (
                          <li>
                            <span>Facebook</span> :{" "}
                            <p>{user?.visitor_facebook}</p>
                          </li>
                        )}

                        <li>
                          <span>Email</span> :{" "}
                          <p>{visitorUser?.visitor_email}</p>
                        </li>

                        {user?.visitor_phone && (
                          <li>
                            <span>Phone</span> : <p>{user?.visitor_phone}</p>
                          </li>
                        )}
                        {user?.visitor_website && (
                          <li>
                            <span>Website</span> :{" "}
                            <p>{user?.visitor_website}</p>
                          </li>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {user?.visitor_education && (
                  <div className="row">
                    {/* education  */}
                    <div className="section-title-cs edit-sec-cs">
                      <h4 className="profile-title"> Education</h4>

                      <div className="row">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 mb-3">
                          {user?.visitor_education && (
                            <li>
                              <span>Education</span> :{" "}
                              <p>{user?.visitor_education}</p>
                            </li>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </ul>
          </div>
        </div>
      ) : (
        <div>
          <div className="profile-right-side px-3 py-3">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="section-title-cs edit-sec-cs">
                <div className="product-header  d-flex align-items-center justify-content-between ">
                  <h4> </h4>
                  <div
                    onClick={() => {
                      setToggle(!toggle);
                    }}
                    className="pointer"
                  >
                    <p
                      style={{
                        backgroundColor: "#1982c3",
                        color: "white",
                        padding: "5px 7px",
                        borderRadius: "5px",
                      }}
                    >
                      <IoIosArrowBack className="mb-1 me-1" />
                      Profile
                    </p>
                  </div>
                </div>

                <h4 className="component-tittle"> Profile Info</h4>
                <div className="row">
                  <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 mb-3">
                    <label className="control-label">
                      First Name <span className="require">*</span>
                    </label>
                    <div className="col-lg-12 padding_right_0">
                      <input
                        type="text"
                        disabled
                        placeholder="First Name"
                        defaultValue={visitorUser.data?.visitor_first_name}
                        id="f-name"
                        name="first_name"
                        className="form-control "
                      />
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 mb-3">
                    <label className="control-label">
                      Last Name <span className="require">*</span>
                    </label>
                    <div className="col-lg-12 padding_right_0">
                      <input
                        type="text"
                        placeholder="Last Name"
                        disabled
                        defaultValue={visitorUser.data?.visitor_last_name}
                        name="last_name"
                        className="form-control "
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 mb-3">
                    <label className="control-label">Email </label>
                    <div className="col-lg-12 padding_right_0">
                      <input
                        type="email"
                        disabled
                        placeholder="Email"
                        defaultValue={visitorUser.data?.visitor_email}
                        name="email"
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 mb-3">
                    <label className="control-label">
                      Mobile No <span className="require">*</span>{" "}
                    </label>
                    <div className="input-group">
                      <input
                        defaultValue={visitorUser.data?.visitor_phone}
                        type="number"
                        placeholder="Mobile No"
                        {...register("visitor_phone")}
                        className="form-control "
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 mb-3">
                    <label className="control-label col-lg-12 ">
                      Gender <span className="require">*</span>{" "}
                    </label>
                    <div>
                      <select
                        {...register("visitor_gender")}
                        className="form-select"
                      >
                        <option value="male">male</option>
                        <option value="female">female</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 mb-3">
                    <label className="control-label">Date Of Birth </label>
                    <div className="col-sm-12">
                      <input
                        type="date"
                        onChange={(e) => setDate(e.target.value)}
                        className="form-control date_of_birth "
                        placeholder="Date of Birth"
                        value={date}
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 mb-3">
                    <label className="control-label">Education</label>
                    <div className="col-sm-12">
                      <select
                        {...register("visitor_education")}
                        className="form-select"
                      >
                        <option disabled>-- Select Education --</option>
                        <option value="S.S.C">S.S.C</option>
                        <option value="H.S.C">H.S.C</option>
                        <option value="Graduation">Graduation</option>
                        <option value="Masters">Masters</option>
                        <option value="PHD">PHD</option>
                        <option value="Others">Others</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 mb-3">
                    <label className="control-label">Profession</label>
                    <div className="col-sm-12 padding_0">
                      <select
                        {...register("visitor_profession")}
                        className="form-select"
                      >
                        <option disabled>-- Select Profession --</option>
                        <option value="Business">Business</option>
                        <option value="Govt. Job">Govt. Job</option>
                        <option value="Private Job">Private Job</option>
                        <option value="Doctor">Doctor</option>
                        <option value="Engineer">Engineer</option>
                        <option value="Teacher">Teacher</option>
                        <option value="Student">Student</option>
                        <option value="Software Engineer">
                          Software Engineer
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 mb-3">
                    <label className="control-label">Organization</label>
                    <div className="col-lg-12padding_right_0">
                      <input
                        type="text"
                        placeholder="Enter Organization"
                        {...register("visitor_organization")}
                        id="p-name"
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 mb-3">
                    <label className="control-label">Designation</label>
                    <div className="col-lg-12padding_right_0">
                      <input
                        type="text"
                        placeholder="Enter Designation"
                        {...register("visitor_designation")}
                        id="designation"
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                    <label className="control-label">Website</label>
                    <div className="col-lg-12padding_right_0">
                      <input
                        type="text"
                        placeholder="Enter Website"
                        {...register("visitor_website")}
                        id="website"
                        className="form-control"
                        defaultValue={visitorUser.data?.visitor_website}
                      />
                    </div>
                  </div>
                </div>
                <div className="section-title-cs edit-sec-cs">
                  <h4 className="profile-title"> Address &amp; Location </h4>
                  <div className="row mb-3">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                      <label className="control-label">
                        Address <span className="require">*</span>
                      </label>
                      <div className="col-sm-12">
                        <textarea
                          className="form-control "
                          {...register("visitor_address")}
                          placeholder="Address"
                          defaultValue={visitorUser?.visitor_address}
                        ></textarea>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 mb-3">
                      <label className="control-label">
                        Zip <span className="require">*</span>
                      </label>
                      <div className="col-sm-12">
                        <input
                          type="text"
                          className="form-control "
                          {...register("visitor_zip_code")}
                          placeholder="Zip Code"
                          defaultValue={visitorUser?.visitor_zip_code}
                        />
                      </div>
                    </div>

                    <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 mb-3">
                      <label className="control-label">City </label>
                      <div className="col-sm-12">
                        <input
                          type="text"
                          className="form-control"
                          {...register("visitor_city")}
                          placeholder="City"
                          defaultValue={visitorUser?.visitor_city}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="section-title-cs edit-sec-cs">
                  <h4 className="profile-title"> Social Media</h4>

                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 mb-3">
                      <label className="control-label">
                        Facebook<span className="require"></span>
                      </label>
                      <div className="col-lg-12 padding_right_0">
                        <input
                          type="url"
                          placeholder="Enter facebook profile url"
                          id="facebook"
                          className="form-control  "
                          name="facebook"
                          defaultValue={visitorUser?.visitor_facebook}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 mt-4">
                  <div className="col-lg-offset-2 col-lg-12 text-end">
                    {loading ? (
                      <Loader css={"text-white"} />
                    ) : (
                      <Button
                        style={{
                          backgroundColor: "#1982c3",
                          color: "white",
                          borderRadius: "5px",
                          border: "none",
                        }}
                        type="submit"
                        value="1"
                        name="update_profile"
                      >
                        Update
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default VisitorProfileHomePage;
