import { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import { IVisitorInvoice } from "../../Types/VisitorAllTypes";
import { VisitorAuthContext } from "../../Context/AuthContex/VisitorAuthContext";
import { Spinner } from "react-bootstrap";
import moment from "moment";
import axios from "axios";
import { BaseUrl } from "../../Helpers/Constant";

const VisitorInvoice = () => {
  const { visitorUser } = VisitorAuthContext();
  const [VisitorInvoice, setVisitorInvoice] = useState<IVisitorInvoice>();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setLoading(true);
    try {
      (async () => {
        const { data } = await axios.get(
          `${BaseUrl}/api/fair/visitor/get/single/visitor-invoice/by/${visitorUser?.visitor_id}`
        );
        if (data.success) {
          setVisitorInvoice(data.data);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })();
    } catch (error) {
      setLoading(false);
    }
    setLoading(false);
  }, [visitorUser?.visitor_id]);

  return (
    <>
      <div className="profile-right-side px-3 py-3">
        <h4 className="component-tittle">Invoice Information</h4>
        <div className="profile-right-info pt-3 ps-2">
          {loading ? (
            <div className="text-center mt-3">
              <Spinner animation="border" variant="primary" />
            </div>
          ) : (
            <>
              {VisitorInvoice?.visitor_payment_invoice_id ? (
                <Table striped responsive bordered hover size="sm">
                  <thead>
                    <tr className="text-center">
                      <th>Id</th>
                      <th>Date</th>
                      <th>Amount</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="text-center">
                      <td>{VisitorInvoice?.visitor_payment_invoice_id}</td>
                      <td>
                        {moment(
                          VisitorInvoice?.visitor_payment_invoice_issue_date
                        ).format("MMM Do YY")}
                      </td>
                      <td>
                        {VisitorInvoice?.visitor_payment_invoice_item_amount}
                      </td>
                      <td>
                        {" "}
                        <p
                          className={
                            VisitorInvoice?.visitor_payment_invoice_status ===
                            "paid"
                              ? "approved"
                              : "pending"
                          }
                        >
                          {VisitorInvoice?.visitor_payment_invoice_status}
                        </p>
                      </td>
                      {VisitorInvoice?.visitor_payment_invoice_status ===
                      "pending" ? (
                        <td>Pay</td>
                      ) : (
                        <td>Paid</td>
                      )}
                    </tr>
                  </tbody>
                </Table>
              ) : (
                <h5 className="text-danger text-center">No Invoice</h5>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default VisitorInvoice;
