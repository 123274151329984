import { useEffect } from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import Loader from "../Loader/Loader";
// import { SpeakerAuthContext } from "../../Context/AuthContex/SpeakerAuthContext";
import { VisitorAuthContext } from "../../Context/AuthContex/VisitorAuthContext";
// import { useAuthContext } from "../../Context/AuthContex/AuthContex";

const Layout = ({ children }: any) => {
  // const { isLoading: exhibitorLoading } = useAuthContext();
  const { isLoading: visitorLoading } = VisitorAuthContext();
  // const { isLoading: speakerLoading } = SpeakerAuthContext();

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  if (visitorLoading) {
    return <Loader />;
  }

  return (
    <>
      <Header />
      <main style={{ overflow: "hidden" }}>{children}</main>
      <Footer />
    </>
  );
};

export default Layout;
