import { VisitorAuthContext } from "../../Context/AuthContex/VisitorAuthContext";
import { Navigate } from "react-router-dom";
import Loader from "../Loader/Loader";

interface PropType {
  component: JSX.Element;
}
const VisitorPrivateRoute = ({ component }: PropType) => {
  const { isLoading } = VisitorAuthContext();
  const token = localStorage.getItem("token");
  if (isLoading) {
    return <Loader />;
  }
  if (token) {
    return component;
  } else {
    return <Navigate to="/visitor-login" />;
  }
};

export default VisitorPrivateRoute;
