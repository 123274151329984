import { useState } from "react";
import Layout from "../../Components/Layout/Layout";
import CompanyProfile from "../../Components/RegistrationComponents/CompanyProfile";
import ContactPerson from "../../Components/RegistrationComponents/ContactPerson";
import PortalLogin from "../../Components/RegistrationComponents/PortalLogin";
import { useForm } from "react-hook-form";
import Toaster from "../../Components/Toaster/Toaster";
import { ICompanyProfileInfoRegister, Ilogo } from "../../Types/RegisterTypes";
import HeaderBottom from "../../Components/HeaderBottom/HeaderBottom";
import { useAuthContext } from "../../Context/AuthContex/AuthContex";
import { useNavigate } from "react-router-dom";
import SpaceRequirements from "../../Components/RegistrationComponents/SpaceRequirments";
import { BaseUrl } from "../../Helpers/Constant";
import axios from "axios";

const BookYourSpace = () => {
  const { setIsLoading } = useAuthContext();
  const Toast = Toaster();
  const navigate = useNavigate();
  const { register, handleSubmit, reset } = useForm();
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [companyLogo, setCompanyLogo] = useState<Ilogo>({
    companyLogoPhoto: "",
    companyLogoAi: "",
  });

  const [companyProfileReg, setCompanyProfileReg] =
    useState<ICompanyProfileInfoRegister>();
  const [stalls, setStalls] = useState<any>([]);
  let companyNumber = "";
  const {
    company_name,
    company_address,
    company_website,
    company_email,
    company_phone,
  } = companyProfileReg || {};

  const onSubmit = async (data: any) => {
    setIsLoading?.(true);
    if (companyNumber) {
      data.atab_user_member_id = companyNumber;
    }
    data.stalls = JSON.stringify(stalls);

    data.user_fair_member_company_name =
      data.user_fair_member_company_name || company_name;

    data.user_fair_member_company_address =
      data.user_fair_member_company_address || company_address;

    data.user_fair_member_company_website =
      data.user_fair_member_company_website || company_website;

    data.user_fair_member_company_telephone =
      data.user_fair_member_company_telephone || company_phone;

    data.user_fair_member_company_email =
      data.user_fair_member_company_email || company_email;

    if (!stalls.length) {
      Toast.fire({
        icon: "error",
        title: `Please select stall`,
      });
      setIsLoading?.(false);
      return;
    }
    if (data.user_fair_member_password !== confirmPassword) {
      Toast.fire({
        icon: "error",
        title: `Password does not match`,
      });
      setIsLoading?.(false);
      return;
    }
    if (data.user_fair_member_password.length < 8) {
      Toast.fire({
        icon: "error",
        title: `Password must be at least 8 characters`,
      });
      setIsLoading?.(false);
      return;
    }
    if (
      data.user_fair_member_contact_number.startsWith("01") &&
      data.user_fair_member_contact_number === 11
    ) {
      Toast.fire({
        icon: "error",
        title: `Enter valid phone number`,
      });
      setIsLoading?.(false);
      return;
    }

    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      if (typeof data[key] === "object") {
        formData.append(key, data[key][0]);
      } else {
        formData.append(key, data[key]);
      }
    });
    if (companyLogo.companyLogoPhoto) {
      formData.append(
        "user_fair_member_company_logo",
        companyLogo.companyLogoPhoto
      );
    }
    if (companyLogo.companyLogoAi) {
      formData.append(
        "user_fair_member_company_logo_ai",
        companyLogo.companyLogoAi
      );
    }

    try {
      const { data: res } = await axios.post(
        `${BaseUrl}/api/auth/fair-member/register`,
        formData
      );

      if (res.success) {
        Toast.fire({
          icon: "success",
          title: `Successfully register`,
        });
        setIsLoading?.(false);
        reset();
        navigate("/profile");
      } else {
        Toast.fire({
          icon: "error",
          title: res.message,
        });
      }
    } catch (error: any) {
      setIsLoading?.(false);
      Toast.fire({
        icon: "error",
        title: `${error.data.response.message}`,
      });
    }
    setIsLoading?.(false);
  };

  return (
    <Layout>
      <div>
        <HeaderBottom pathName={"Book your space"} />
        <div className='container  pt-5'>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div>
              <div>
                <CompanyProfile
                  register={register}
                  companyProfileReg={companyProfileReg}
                  setCompanyLogo={setCompanyLogo}
                  companyLogo={companyLogo}
                />
              </div>
              <div>
                <SpaceRequirements
                  setStalls={setStalls}
                  register={register}
                  stalls={stalls}
                />
              </div>
              <div>
                <ContactPerson register={register} />
              </div>
              <div>
                <PortalLogin
                  register={register}
                  setConfirmPassword={setConfirmPassword}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </Layout>
  );
};

export default BookYourSpace;
