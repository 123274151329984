import Layout from "../../Components/Layout/Layout";
import HeaderBottom from "../../Components/HeaderBottom/HeaderBottom";
import { Table } from "react-bootstrap";
import { useEffect, useState } from "react";
import axios from "axios";
import { BaseUrl } from "../../Helpers/Constant";
import { IRaffleDraw } from "../../Types/RaffleDrawTypes";
import Loader from "../../Components/Spinner/Loader";

const RaffleDraw = () => {
  const [winnersList, setWinnersList] = useState<IRaffleDraw[]>([]);
  const [loading, setLoading] = useState(false);

  // const currentDate = new Date().toISOString().slice(0, 10);
  // console.log(new Date().toISOString().slice(0, 10));

  useEffect(() => {
    setLoading(true);
    try {
      (async () => {
        const { data } = await axios.get(
          `${BaseUrl}/api/fair/raffle-draw?by_date=${new Date()
            .toISOString()
            .slice(0, 10)}`
        );

        if (data.success) {
          setWinnersList(data.data);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })();
    } catch (err: any) {
      setLoading(false);
    }
  }, []);

  return (
    <div>
      <Layout>
        <HeaderBottom pathName={"Raffle Draw "} />

        <div style={{ padding: "20px" }}>
          <h1 className="text-center">Raffle Draw Winners</h1>

          <div style={{ margin: "auto", maxWidth: "1000px" }}>
            <Table striped bordered hover responsive>
              <thead>
                <tr>
                  <th>Position</th>
                  <th>Winner Name</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td colSpan={100} className="text-center">
                      <Loader />
                    </td>
                  </tr>
                ) : (
                  <>
                    {winnersList.length ? (
                      <>
                        {winnersList.map((winner) => (
                          <tr key={winner.visitor_id}>
                            <td>{winner.wining_position}</td>
                            <td>
                              {winner.visitor_first_name}{" "}
                              {winner.visitor_last_name}
                            </td>
                          </tr>
                        ))}
                      </>
                    ) : (
                      <tr>
                        <td className="text-center fs-5 fw-bold" colSpan={100}>
                          No winner found
                        </td>
                      </tr>
                    )}
                  </>
                )}
              </tbody>
            </Table>
          </div>
        </div>
      </Layout>
    </div>
  );
};

export default RaffleDraw;
