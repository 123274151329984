import { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import Layout from "../../Components/Layout/Layout";
import { FaDownload } from "react-icons/fa";
import { Button, Container, Spinner, Table } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { BsCalendarCheckFill } from "react-icons/bs";
import { FaUserTie } from "react-icons/fa";
import { BiTimeFive } from "react-icons/bi";
import { MdLocationPin } from "react-icons/md";
import moment from "moment";
import { BaseUrl, imgUrl } from "../../Helpers/Constant";
import SingleEventSpeaker from "./SingleEventSpeaker";
import SpeakerModal from "./SpeakerModal";
import axios from "axios";
import { SpeakerAuthContext } from "../../Context/AuthContex/SpeakerAuthContext";
import UpdateMaterial from "../../Components/UpdateMaterial/UpdateMaterial";
import { AiFillEdit } from "react-icons/ai";
import Loader from "../../Components/Loader/Loader";
import { ISingleEvent } from "../../Types/EventsType";

type IMaterial = {
  sm_id: number;
  sm_details: string;
  sm_file: string;
  sm_visiblity: string;
};

const SingleEvent = () => {
  const { id } = useParams(); // event_id
  let location = useLocation();
  const [materials, setMaterial] = useState<IMaterial[]>([]);
  const [singleEvent, setSingleEvent] = useState<ISingleEvent>();
  const [loading, setLoading] = useState(false);
  const [materialId, setMaterialID] = useState();
  // modal
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  // modal 2
  const [showModal, setShowModal] = useState(false);
  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = (mID: any) => {
    setMaterialID(mID);
    setShowModal(true);
  };

  let event_speaker_id = location?.search.split("=")[1];
  const { speakerUser } = SpeakerAuthContext();

  useEffect(() => {
    setLoading(true);
    try {
      (async () => {
        const { data } = await axios.get(
          `${BaseUrl}/api/fair/guest-speaker/get/materials/with/eventId/${id}/speakerId/${speakerUser.guest_speaker_id}`
        );
        if (data?.success) {
          setMaterial(data?.data);

          setLoading(false);
        } else {
          setLoading(false);
        }
      })();
    } catch (error) {
      setLoading(false);
    }
  }, [id, speakerUser.guest_speaker_id]);

  useEffect(() => {
    setLoading(true);
    try {
      (async () => {
        const { data } = await axios.get(
          `${BaseUrl}/api/fair/event/get/single/${id}`
        );

        if (data.success) {
          setSingleEvent(data.data);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })();
    } catch (error) {
      setLoading(false);
    }
  }, [id]);

  return (
    <>
      {!loading ? (
        <Layout Layout className='my-5 overflow-hidden'>
          <div
            style={{
              paddingTop: "50px",
              paddingBottom: "50px",
              backgroundColor: "#f5f5f5",
            }}
          >
            <Container>
              {loading ? (
                <div className='text-center'>
                  <Spinner
                    variant='primary'
                    style={{ height: "50px", width: "50px" }}
                  />
                </div>
              ) : (
                <div>
                  <div className='events-matrix-content event-details-main-cs'>
                    <div className='events-title '>
                      <h1>{singleEvent?.event_title}</h1>
                    </div>

                    <Row>
                      <Col xs={12} md={{ span: 7, order: "first" }}>
                        <div className='divider d-none d-md-block'></div>
                        <div className='events-matrix-info mt-3 '>
                          <div className='w-50 w-sm-100'>
                            <ul>
                              <li>
                                <div className='info-icon'>
                                  <BsCalendarCheckFill />
                                </div>
                                <div className='info-content'>
                                  <span>Date </span>
                                  <p>
                                    {moment(singleEvent?.event_date).format(
                                      "MMM Do YY"
                                    )}
                                  </p>
                                </div>
                              </li>

                              <li>
                                <div className='info-icon'>
                                  <FaUserTie />
                                </div>
                                <div className='info-content'>
                                  <span>Speakers </span>
                                  <p>
                                    {singleEvent?.event_total_speaker}{" "}
                                    Speaker(s)
                                  </p>
                                </div>
                              </li>
                            </ul>
                          </div>
                          <div className='w-50 w-sm-100'>
                            <ul>
                              <li>
                                <div className='info-icon'>
                                  <BiTimeFive />
                                </div>
                                <div className='info-content'>
                                  <span>Time </span>
                                  <p>
                                    {" "}
                                    {moment(
                                      singleEvent?.event_start_time,
                                      "HH:mm:ss"
                                    ).format("hh:mm A")}{" "}
                                    {singleEvent?.event_end_time && (
                                      <>
                                        - {""}
                                        {moment(
                                          singleEvent?.event_end_time,
                                          "HH:mm:ss"
                                        ).format("hh:mm A")}
                                      </>
                                    )}
                                  </p>
                                </div>
                              </li>
                              <li>
                                <div className='info-icon'>
                                  <MdLocationPin />
                                </div>
                                <div className='info-content'>
                                  <span>Venue </span>
                                  <p>{singleEvent?.event_venu}</p>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>

                        <div className='ceremony-footer'>
                          <Button onClick={handleShow} variant='primary'>
                            Add Materials
                          </Button>
                          <SpeakerModal
                            id={id}
                            event_speaker_id={event_speaker_id}
                            handleClose={handleClose}
                            show={show}
                            setMaterial={setMaterial}
                            materials={materials}
                          />
                        </div>
                      </Col>
                      <Col
                        md={5}
                        xs={{ span: 12, order: "first" }}
                        className='mt-3 mt-md-0'
                      >
                        <div>
                          <img
                            className='w-100'
                            src={`${imgUrl}/event_files/${singleEvent?.event_photo}`}
                            alt=''
                          />
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <div className='events-matrix-content mt-1'>
                    <div className='events-title events-title-style-2 text-left'>
                      <h1>Details</h1>
                    </div>
                    <div className='events-title event-details-text text-align-justify'>
                      <p>{singleEvent?.event_description}</p>
                    </div>
                  </div>

                  <div className='text-center'>
                    <h3>Speakers</h3>
                  </div>

                  <SingleEventSpeaker id={id} />
                </div>
              )}
            </Container>
            {/* {materials?.length && <AllMaterials  material={materials} />} */}

            {/* single materials  */}

            <Col />
            <Row className='d-flex justify-content-center'>
              <Col xs={12} md={12}>
                <Container>
                  <h2 className='mx-auto text-center my-5'>Materials</h2>
                  <UpdateMaterial
                    materialId={materialId}
                    handleCloseModal={handleCloseModal}
                    showModal={showModal}
                    setMaterial={setMaterial}
                    materials={materials}
                  />
                  <Row>
                    <Table bordered responsive>
                      <thead>
                        <tr>
                          <th>Material Details</th>
                          <th className='text-center'>Status</th>
                          <th className='text-center'>Edit</th>
                          <th className='text-center'>Download</th>
                        </tr>
                      </thead>
                      <tbody>
                        {materials.length ? (
                          <>
                            {materials.map((material) => {
                              return (
                                <tr>
                                  <td width={700}>
                                    <p className='card-text'>
                                      {material?.sm_details}
                                    </p>
                                  </td>
                                  <td>
                                    {material?.sm_visiblity ? (
                                      <p className='card-text text-center text-success fw-bold'>
                                        Published
                                      </p>
                                    ) : (
                                      <p className='card-text text-center text-danger fw-bold'>
                                        Unpublished
                                      </p>
                                    )}
                                  </td>
                                  <td style={{ cursor: "pointer" }}>
                                    {" "}
                                    <p
                                      onClick={() => handleShowModal(material)}
                                      className='text-center my-0 py-0'
                                    >
                                      <AiFillEdit />
                                    </p>
                                  </td>
                                  <td style={{ cursor: "pointer" }}>
                                    <a
                                      rel='noreferrer'
                                      className='text-center d-block  my-0 py-0'
                                      target='_blank'
                                      href={`${imgUrl}asian_tourism_fair/guest_speaker_material_files/${material?.sm_file}`}
                                    >
                                      <FaDownload />
                                    </a>
                                  </td>
                                </tr>
                              );
                            })}
                          </>
                        ) : (
                          <tr>
                            <td
                              style={{
                                textAlign: "center",
                                color: "red",
                                fontWeight: "bold",
                              }}
                              colSpan={100}
                            >
                              No data found
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>

                    {/* {materials?.length ? <Table bordered responsive>
                      <thead>
                        <tr>
                          <th >Material Details</th>
                          <th className='text-center'>Status</th>
                          <th className='text-center'>Edit</th>
                          <th className='text-center'>Download</th>
                        </tr>
                      </thead>
                      <tbody>

                        {materials.length ? <>


                          {materials?.map(material => {


                            return (<tr >
                              <td width={700}><p className="card-text">{material?.sm_details}</p></td>
                              <td >{material?.sm_visiblity ? <p className="card-text text-center text-success fw-bold">Published</p> : <p className="card-text text-center text-danger fw-bold">Unpublished</p>}</td>
                              <td style={{ cursor: 'pointer' }} > <p onClick={() => handleShowModal(material)} className='text-center my-0 py-0'><AiFillEdit /></p></td>
                              <td style={{ cursor: 'pointer' }} ><a className='text-center d-block  my-0 py-0' target='_blank' href={imgUrl + `asian_tourism_fair/guest_speaker_material_files/${material?.sm_file}`}><FaDownload /></a></td>
                            </tr>)
                          })}



                        </> : <h3 className='fw-bold text-danger text-center'>Materials Not found !</h3>}






                        {materials?.length && materials.map((material: any) => (<tr >
                          <td width={700}><p className="card-text">{material?.sm_details}</p></td>
                          <td >{material?.sm_visiblity ? <p className="card-text text-center text-success fw-bold">Published</p> : <p className="card-text text-center text-danger fw-bold">Unpublished</p>}</td>
                          <td style={{ cursor: 'pointer' }} > <p onClick={() => handleShowModal(material)} className='text-center my-0 py-0'><AiFillEdit /></p></td>
                          <td style={{ cursor: 'pointer' }} ><a className='text-center d-block  my-0 py-0' target='_blank' href={imgUrl + `asian_tourism_fair/guest_speaker_material_files/${material?.sm_file}`}><FaDownload /></a></td>
                        </tr>))}
                      </tbody>
                    </Table> : <h3 className='fw-bold text-danger text-center'>Materials Not found !</h3>} */}
                  </Row>
                </Container>
              </Col>
            </Row>
          </div>
        </Layout>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default SingleEvent;
