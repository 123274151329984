import React, { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Toaster from '../Toaster/Toaster';
import { useForm } from 'react-hook-form';
import Layout from '../Layout/Layout';
import HeaderBottom from '../HeaderBottom/HeaderBottom';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import fetcher from '../../Helpers/Fetcher/fetchApi';
import Loader from '../Spinner/Loader';

const MatchOtp = () => {
  const [searchParams] = useSearchParams();
  const email = searchParams.get('email');
  const Toast = Toaster();
  const navigate = useNavigate();
  const { register, handleSubmit ,reset} = useForm();
  const [loading, setLoading] = useState(false);

  const onSubmit = async (data: any) => {
    data.email = email;
    data.type = 'forget_fair';

    const res = await fetcher.post({
      url: '/api/atab/common/match-email-otp',
      contentType: 'application/json',
      body: data,
    });

    if (res.success) {
      Toast.fire({
        icon: 'success',
        title: res.message,
      });
      localStorage.setItem('fair_forget_token', res.token);
      navigate(`/new-password?email=${data.email}`);
      setLoading(false);
    } else {
      Toast.fire({
        icon: 'error',
        title: res.message,
      });
      setLoading(false);
    }
    reset()
  };
  return (
    <>
      <Layout>
        <HeaderBottom pathName={'Match OTP'} />
        <div className='login-wrapper'>
          <div className='login-div'>
            <div className='login-page'>
              <h1 className='text-center pt-3'>Match OTP</h1>
              <div className='login-form'>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div>
                    <label htmlFor='' className='pb-2'>
                      Enter OTP
                    </label>
                    <br />
                    <input
                      placeholder='Enter OTP'
                      className='inputFiled'
                      {...register('otp')}
                      type='number'
                    />
                  </div>

                  <div>
                    {loading ? (
                      <Loader css={'w-100 mt-5 text-white'} />
                    ) : (
                      <Button
                        type='submit'
                        className='w-100 mt-5 submit-button'
                      >
                        Continue
                      </Button>
                    )}
                  </div>

                  <div className='lost-your-password pt-5 text-center'>
                    <Link to='/login' className='forget-link'>
                      Go back
                    </Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default MatchOtp;
