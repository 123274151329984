import {
  AUTH_SPEAKER_USER_FAILED,
  AUTH_SPEAKER_USER_SUCCESS,
  SPEAKER_PROFILE_IMAGE_UPDATE,
} from "../../Helpers/Constant";

const speakerReducer = (state: any, action: any) => {
  switch (action.type) {
    case AUTH_SPEAKER_USER_SUCCESS:
      return {
        ...state,
        speakerUser: action.payload,
      };
    case SPEAKER_PROFILE_IMAGE_UPDATE:
      const updateUser = { ...state.speakerUser, ...action.payload };
      return { ...state, speakerUser: updateUser };
    case AUTH_SPEAKER_USER_FAILED:
      return { ...state, speakerUser: {} };
    default:
      throw new Error(`No matching action type - ${action.type}`);
  }
};

export default speakerReducer;
