import { useState } from "react";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
// import Form from "react-bootstrap/Form";
import { Stack } from "react-bootstrap";
import VisitorModal from "../VisitorEventList/VisitorModal";
import { imgUrl } from "../../Helpers/Constant";
// import { TbCurrencyTaka } from "react-icons/tb";
import { AiOutlineCloseCircle } from "react-icons/ai";

const SeminarEventSidebar = ({
  show,
  handleClose,
  cart,
  setCart,
  purchase,
  setPurchase,
}: any) => {
  // const [checkStudent, setCheckStudent] = useState<boolean>(false);
  const [showModal, setShowModal] = useState(false);

  const handelRemove = (id: number) => {
    const filterCart = cart.filter((s: any) => s.event_id !== id);
    localStorage.setItem("seminar_item", JSON.stringify(filterCart));
    setCart(filterCart);
  };

  // const totalPrice = cart?.reduce(
  //   (total: number, sItem: any) => total + sItem.price,
  //   0
  // );

  // const subTotal = checkStudent ? 0 : totalPrice;

  return (
    <div>
      <Offcanvas show={show} onHide={handleClose} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Cart</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Stack gap={3}>
            {cart?.length ? (
              <>
                {cart?.map((sCart: any, index: number) => {
                  return (
                    <div
                      className="card p-0 m-0 position-relative d-block"
                      key={index}
                    >
                      <div className="row g-2">
                        <div className="col-md-4">
                          <img
                            src={`${imgUrl}asian_tourism_fair/event_files/${sCart?.event_photo}`}
                            className="img-fluid rounded-start"
                            style={{ width: "100%", height: "100%" }}
                            alt="..."
                          />
                        </div>
                        <div className="col-md-8 ">
                          <div className="card-body">
                            <h5
                              className="card-title fw-bolder"
                              title={sCart.event_title}
                            >
                              {`${sCart.event_title.slice(0, 50)}...`}
                            </h5>

                            {/* <p className="card-text my-1">
                              <span>
                                <TbCurrencyTaka className="pr-1" />
                              </span>
                              <span className="ms-1 d-inline-block">
                                {sCart?.price}
                              </span>
                            </p> */}
                          </div>
                        </div>
                      </div>
                      <AiOutlineCloseCircle
                        size={25}
                        className="pointer"
                        style={{
                          position: "absolute",
                          top: 5,
                          right: 15,
                          color: "red",
                        }}
                        onClick={() => handelRemove(sCart?.event_id)}
                      />
                    </div>
                  );
                })}
                {/* <div>
                  <hr />
                  <div className="d-flex  align-item-center justify-content-between mx-4">
                    <p>SubTotal</p>

                    <p className="d-flex align-items-center">
                      <TbCurrencyTaka />
                      {subTotal}
                    </p>
                  </div>
                </div> */}
                {/* <div className="mt-2">
                  <Form.Check // prettier-ignore
                    type="checkbox"
                    onChange={(e) => {
                      setCheckStudent(!checkStudent);
                      // if (e.target.checked) {
                      // 	setShowModal(true);
                      // }
                    }}
                    id={`default`}
                    label={`Are you student?`}
                  />
                </div> */}
                <div>
                  <Button
                    type="submit"
                    className="w-100  me-5 submit-button "
                    onClick={() => {
                      setShowModal(true);
                      // localStorage.setItem(
                      //   "seminar_item",
                      //   JSON.stringify(cart)
                      // );
                    }}
                  >
                    Submit
                  </Button>
                </div>
              </>
            ) : (
              <p className="text-center fs-4 text-danger fw-bold">
                No data found
              </p>
            )}
          </Stack>
        </Offcanvas.Body>
      </Offcanvas>
      <VisitorModal
        show={showModal}
        // checkStudent={checkStudent}
        handleClose={() => setShowModal(false)}
        cart={cart}
        // subTotal={subTotal}
        handelCloseSidebar={handleClose}
        setCart={setCart}
        purchase={purchase}
        setPurchase={setPurchase}
      />
    </div>
  );
};

export default SeminarEventSidebar;
